<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap11PackagesAndImports"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 11: Packages and imports" image-name="package-imports.jpg" image-alt="Packages and imports"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a package?</p></li>
<li><p>How are source files grouped?</p></li>
<li><p>Where to store the main.go file?</p></li>
<li><p>How to organize your Go project?</p></li>
<li><p>What is the import path? What are import declarations?</p></li>
<li><p>What is the go.mod file?</p></li>
<li><p>What is modular programming?</p></li>
<li><p>How to build modular applications with Go?</p></li>
<li><p>What is the internal directory, and why use it?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>package</p></li>
<li><p>source file</p></li>
<li><p>import</p></li>
<li><p>module</p></li>
<li><p>modular programming</p></li>
</ul>
<div id="program-package-source-files" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Program, package, source files <a href="#program-package-source-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A Go program is a combination of packages (see figure <a href="#fig:A-Go-program" data-reference-type="ref" data-reference="fig:A-Go-program">1</a>).</p>
<figure>
<b-img :src="require('@/assets/images/go_program_package.png')" alt="A Go program[fig:A-Go-program]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A Go program<span id="fig:A-Go-program" label="fig:A-Go-program">[fig:A-Go-program]</span></figcaption>
</figure>
<p>A package is composed of one or more <strong>source files</strong>. Into those source files, the Go programmer declares :</p>
<ul>
<li><p>constant</p></li>
<li><p>variables</p></li>
<li><p>functions</p></li>
<li><p>types and methods</p></li>
</ul>
<p>The package main is <strong>often</strong> composed of an individual file. The function <strong>main</strong> is the <strong>program entry point</strong>. In a Go program, you will also find a file named <strong>go.mod.</strong> The following sections will detail all those components.</p>
<div id="source-files" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> &amp;Source files <a href="#source-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the figure <a href="#fig:A-source-file" data-reference-type="ref" data-reference="fig:A-source-file">2</a> you can see a schematic version of a source file. We will detail each part of the source file.</p>
<figure>
<b-img :src="require('@/assets/images/source_file.png')" alt="A source file[fig:A-source-file]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A source file<span id="fig:A-source-file" label="fig:A-source-file">[fig:A-source-file]</span></figcaption>
</figure>
<p>The following snippet is an example of a source file that belongs to a package<strong>occupancy :</strong></p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/occupancy/occupancy.go
package occupancy

const highLimit = 70.0
const mediumLimit = 20.0

// retrieve occupancyLevel from an occupancyRate
// From 0% to 30% occupancy rate return Low
// From 30% to 60% occupancy rate return Medium
// From 60% to 100% occupancy rate return High
func level(occupancyRate float32) string {
    if occupancyRate &gt; highLimit {
        return &quot;High&quot;
    } else if occupancyRate &gt; mediumLimit {
        return &quot;Medium&quot;
    } else {
        return &quot;Low&quot;
    }
}

// compute the hotel occupancy rate
// return a percentage
// ex : 14,43 =&gt; 14,43%
func rate(roomsOccupied int, totalRooms int) float32 {
    return (float32(roomsOccupied) / float32(totalRooms)) * 100
}</code></pre>
<div id="package-clause" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Package clause <a href="#package-clause"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>At the top of the source file, we find the <strong>package clause</strong> in our example, it’s :</p>
<pre v-highlightjs><code class="go" v-pre >package occupancy</code></pre>
<p>The <strong>package clause</strong> is the first line of each source file. It defines the name of the current package.</p>
<div id="imports-declaration" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Imports declaration <a href="#imports-declaration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Then the <strong>set of imports declarations.</strong>In this section of the source file, we define all the other packages that we want to use in this package. The package occupancy does not import other packages. Let’s take another example: here is a source file from the package <span v-highlightjs><code class="go" v-pre style="display: inline">room</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/import-declaration/room/room.go
package room

import &quot;fmt&quot;

// display information about a room
func printDetails(roomNumber, size, nights int) {
    fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}

import &quot;fmt&quot;</code></pre>
<p>Here we import one package :</p>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span> wich is part of the standard library</li>
</ul>
<div id="source-code" class="anchor"></div>
<h2 data-number="4.3"><span class="header-section-number">4.3</span> Source code <a href="#source-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>After the imports declarations, we find the most important part, the source code of the package. This is where you can declare variables, constants, functions, types, and methods.</p>
<div id="file-organization" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> File organization <a href="#file-organization"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We must group the source files of a package into a single directory. The directory must have the same name as the package. For instance, the source files of the <strong>baz</strong> package must be stored into the <strong>baz</strong> folder (see figure <a href="#fig:Location-of-package&#39;s" data-reference-type="ref" data-reference="fig:Location-of-package&#39;s">3</a> )</p>
<p><b-img :src="require('@/assets/images/package_storage.png')" alt="Location of package’s source files[[fig:Location-of-package&#39;s]]{#fig:Location-of-package’s label=“fig:Location-of-package’s”}"  fluid thumbnail class="img-book"></b-img>{#fig:Location-of-package’s width=“80%”}</p>
<div id="the-main-package" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> The main package <a href="#the-main-package"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A Go program starts by initializing the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> package and then run the function <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> from that package. The main package is where your program starts doing what it was built to do.</p>
<p>Here is an example of a main package :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/main-package/main.go
package main

import &quot;fmt&quot;

func init() {
    fmt.Println(&quot;launch initialization&quot;)
}

func main() {
    fmt.Println(&quot;launch the program !&quot;)
}</code></pre>
<p>This program has an <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> function. This function can hold all the initialization tasks necessary for your program to run correctly (for more information about it, refers to the dedicated chapter).</p>
<p>The program also defines a <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function. Both functions do not have a return type (unlike C, where the main function must return an integer).</p>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function will be executed after all the initialization tasks have been performed. In this function, you usually call other packages and implement your program logic.</p>
<p>The previous program output :</p>
<pre v-highlightjs><code class="go" v-pre >launch initialization
launch the program!</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">init</code></span> function is launched, then the main function.</p>
<div id="one-main-package-per-project" class="anchor"></div>
<h4 data-number="6.0.0.1"><span class="header-section-number">6.0.0.1</span> One main package per project? <a href="#one-main-package-per-project"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>This is not always the case, but a project can have several main packages and thus several main functions. Usually, different main packages are present in large projects. Here are some common examples :</p>
<ul>
<li><p>A main package for launching the web server of the application</p></li>
<li><p>Another main package to run scheduled database maintenance</p></li>
<li><p>Another one that has been developed for a specific punctual intervention...</p></li>
</ul>
<p>For instance, the Kubernetes (one of the most starred Go projects) holds at the time of speaking 20 main packages.</p>
<div id="should-i-name-the-file-that-holds-my-main-package-main.go" class="anchor"></div>
<h4 data-number="6.0.0.2"><span class="header-section-number">6.0.0.2</span> Should I name the file that holds my main package main.go? <a href="#should-i-name-the-file-that-holds-my-main-package-main.go"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>No, it’s not an obligation. You can do that if you have just one main function in your project. But often, it’s good practice to give it an informational name.</p>
<p>For instance, in the project Kubernetes you can find the following files that are holding a main package :</p>
<ul>
<li><p>check_cli_conventions.go</p></li>
<li><p>gen_kubectl_docs.go</p></li>
</ul>
<p>We can infer what the program does just by looking at its name: the first one will check that CLI conventions are respected, the second one will generate docs.</p>
<p>Please follow this convention. It allows other developers to understand what the program does by looking at the file tree.</p>
<div id="our-main-packages-stored-in-a-specific-directory" class="anchor"></div>
<h4 data-number="6.0.0.3"><span class="header-section-number">6.0.0.3</span> Our main packages stored in a specific directory <a href="#our-main-packages-stored-in-a-specific-directory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Again nothing is written in the Go specification about the folder that should contain the main package. There is still a strong usage main packages should live inside a <strong>cmd/</strong> folder at the root of the repository.</p>
<div id="the-go.mod-file" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> The go.mod file <a href="#the-go.mod-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an example of a go.mod file :</p>
<pre v-highlightjs><code class="go" v-pre >module maximilien-andile.com/myProg

go 1.13</code></pre>
<div id="module-path" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Module path <a href="#module-path"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The first line is composed of the word <strong>module</strong> followed by the<u>module path</u>.</p>
<p>We introduce here the notion of <strong>module</strong>. They have not always existed in Go; they have been introduced around March 2019. We will detail what modules are and how to use them in another section. For the moment, remember that a module is “a collection of Go packages stored in a file tree with a go.mod file at its root”<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. When we add a package main to a module, we make it a program that can be compiled and executed.</p>
<p>We need to define a module path for our module (our program). The path is the unique location of the module. Here are some examples of paths for two famous Go programs.</p>
<ul>
<li>Hashicorp Vault,<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a> :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >module github.com/hashicorp/vault</code></pre>
<ul>
<li>Kubernetes,<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a>:</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >module k8s.io/kubernetes</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">"github.com/hashicorp/vault"</code></span> is an URL to a Github repository. A Github repository is a folder that contains the source code of a project. This folder can be publicly accessible (i.e., by anyone who has the link) or private. In the latter case, the code is only accessible to authorized developers. If you have access to a repository that has a go.mod inside, you can import it in your project. We will see later how that works.</p>
<p>Note that your program will not be automatically shared or exposed by Go if you choose a remote path! For testing purpose, you can choose a path that is not an URL :</p>
<pre v-highlightjs><code class="go" v-pre >module thisIsATest

go 1.13</code></pre>
<div id="expected-go-version" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Expected Go version <a href="#expected-go-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The next line of the go.mod file declares Go’s expected version. In this program, version 1.13 of go is expected. By setting it, we say to other developers that our project has been developed with this specific version of Go. In 10 years, Go will evolve, and some stuff that we use now in our program will probably not exists anymore. Programmers from the future will know how to compile it.</p>
<div id="an-example-program" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> An example program <a href="#an-example-program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/tree_go_program_example.png')" alt="Example of a go program folder/file organization[fig:Go-program-example]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Example of a go program folder/file organization<span id="fig:Go-program-example" label="fig:Go-program-example">[fig:Go-program-example]</span></figcaption>
</figure>
<p>The program is composed of 2 directories (in bold) :</p>
<ul>
<li><p>occupancy</p></li>
<li><p>room</p></li>
</ul>
<p>Each directory is composed of a go file. The file has the name of the directory. Each folder represents a package.</p>
<div id="room.go" class="anchor"></div>
<h4 data-number="8.0.0.1"><span class="header-section-number">8.0.0.1</span> room.go <a href="#room.go"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >package room

const roomText = &quot;%d : %d people / %d nights&quot;</code></pre>
<div id="occupancy.go" class="anchor"></div>
<h4 data-number="8.0.0.2"><span class="header-section-number">8.0.0.2</span> occupancy.go <a href="#occupancy.go"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >package occupancy

const highLimit = 70.0</code></pre>
<p>At the root directory of the program folder, we can find the <strong>go.mod</strong> file with the <strong>main.go</strong> file.</p>
<div id="go.mod" class="anchor"></div>
<h4 data-number="8.0.0.3"><span class="header-section-number">8.0.0.3</span> go.mod <a href="#go.mod"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >module thisIsATest

go 1.13</code></pre>
<div id="main.go-the-entry-point" class="anchor"></div>
<h4 data-number="8.0.0.4"><span class="header-section-number">8.0.0.4</span> main.go (the entry point) <a href="#main.go-the-entry-point"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;fmt&quot;

func main() {
   fmt.Println(&quot;program started&quot;)
}</code></pre>
<p>Let’s try to build it and execute it :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main
program started</code></pre>
<p>The program does nothing except that it outputs the string “program started”. In each of the two packages, we defined a constant. Those two constants are never used.</p>
<div id="practical-application-1" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Practical application 1 <a href="#practical-application-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="problem" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Problem <a href="#problem"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Add a package named <strong>booking</strong> to the previous Go program. In this package :</p>
<ul>
<li><p>Define a constant named <span v-highlightjs><code class="go" v-pre style="display: inline">vatRate</code></span> with the value 20.0</p></li>
<li><p>Define a function named <span v-highlightjs><code class="go" v-pre style="display: inline">printVatRate</code></span> that will print to the standard output the constant <span v-highlightjs><code class="go" v-pre style="display: inline">vatRate</code></span> followed by the percentage sign.</p></li>
</ul>
<div id="solution" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A package is composed of a directory and at least one source file. If we want to create a package <span v-highlightjs><code class="go" v-pre style="display: inline">booking</code></span> we should :</p>
<ol type="1">
<li><p>Create a directory named “booking”</p></li>
<li><p>Create a file into this directory. The name of the file will be <strong>booking.go</strong></p></li>
</ol>
<p>Here is the source file booking.go :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/application-add-package/booking/booking.go
package booking

import &quot;fmt&quot;

const vatRate = 20.0

func printVatRate() {
    fmt.Printf(&quot;%.2f %%&quot;, vatRate)
}</code></pre>
<div id="modular-programming" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Modular programming <a href="#modular-programming"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can define a program in a single main.go file. This is what we did before. It’s legal; your code will compile. It’s legal, but it’s not the best solution. To understand why we need to take define the concept of “modular programming”.</p>
<p>Those questions are not new to software engineering and have been asked by many programmers before. During the sixties, software developers were struggling to maintain code bases. Softwares were designed into a single monolith with intensive usage of GOTO statements <b-link class="citation" data-cites="davis2011understanding" href="#davis2011understanding" >[@davis2011understanding]</b-link>. The codebase of some projects became hard to understand due to this design.</p>
<p>The community needed a better way to develop. This was the beginning of the reflection on modular programming.</p>
<div id="what-is-a-module" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> What is a module? <a href="#what-is-a-module"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To define a module, we will use Gauthier’s standard definition.<b-link class="citation" data-cites="gauthier1970designing" href="#gauthier1970designing" >[@gauthier1970designing]</b-link>. A module is a chunk of code that :</p>
<ol type="1">
<li><p>Performs a specific task</p></li>
<li><p>With inputs and outputs well defined</p></li>
<li><p>That can be tested independently</p></li>
</ol>
<p>A large project that requires to perform several tasks can be split into different tasks, each one living inside a module with a well-defined API (2) and that can be tested independently from other systems.</p>
<div id="expected-benefits-of-modular-programming" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Expected benefits of modular programming <a href="#expected-benefits-of-modular-programming"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We can identify three main benefits <b-link class="citation" data-cites="parnas1972criteria" href="#parnas1972criteria" >[@parnas1972criteria]</b-link>:</p>
<p>If you build your systems by using modules, you can make your team work on different modules independently, it increases the expected <strong>productivity</strong> of the team members. For instance, you are positioning two developers on module A and two others on module B. The two groups will not block each other if you have clearly defined the API of the two modules. Modules can also be implemented by others even if they are not finished yet.</p>
<p>Modules increase the <strong>flexibility</strong> of development. You can ship isolated features into modules without having to do heavy changes on the existing code base of your system.</p>
<p>Developers can easily understand code organized in modules. A large system with a lot of structures, interfaces, and files is hard to understand. Joining a project that has thousands of files is hard. A system composed of modules requires less energy to be understood, modules can be studied iteratively. Modularity facilitates the system <strong>comprehension</strong> by developers (especially newcomers to the team)</p>
<div id="how-to-decompose-a-program-into-modules" class="anchor"></div>
<h2 data-number="10.3"><span class="header-section-number">10.3</span> How to decompose a program into modules <a href="#how-to-decompose-a-program-into-modules"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Decomposition of a system into modules can be difficult. There is no unique answer to this issue. Here is some advice that is based on experience and my readings :</p>
<ul>
<li><p>Do not create a module for every single task of a system; you will end up with too many modules with a size that is too small.</p></li>
<li><p>Instead, create modules that group functionalities that are <strong>close</strong>, for instance, a module for handling the database queries, a module to handle logging.</p></li>
<li><p>Inside your modules, the code is usually <strong>tightly coupled</strong>, meaning that the different parts of your module are strongly linked.</p></li>
<li><p>Between two modules, you should enforce <strong>loose coupling</strong>. Each module is treated as a component, and each component do not need another component to work. Modules should be independent. It allows you to replace one module with another one without touching the other modules.</p></li>
</ul>
<div id="modular-programming-go-modules-go-packages" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Modular programming / Go modules / Go packages <a href="#modular-programming-go-modules-go-packages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We introduced three notions :</p>
<ul>
<li><p>Modular programming</p></li>
<li><p>Go packages</p></li>
<li><p>Go modules</p></li>
</ul>
<p>Those three notions are linked together. Modular programming is a way of writing programs. The developer should create testable chunks of codes that perform specific tasks with a well-defined output and output. This “method” can be applied in C, Java, C++, Go...</p>
<ul>
<li><p><strong>Go packages</strong> are a tool that you can use to write <strong>modular programs</strong>. In a Go package :</p>
<ul>
<li><p>We can group source files with functions (or methods) related to a particular functionality :</p>
<ul>
<li><p>Ex: a package booking that will handle bookings for the hotel: create a booking, confirm a booking, cancel a booking...</p></li>
<li><p>Ex: a package room that will group functions related to hotel rooms: display the room information, check its current occupancy...</p></li>
</ul></li>
<li><p>We can write tests that we can run independently of the rest of the code. (we will see how to do it in the unit test chapter)</p></li>
</ul></li>
<li><p><strong>Go modules</strong> are a way of grouping packages together to form an application or a library.</p></li>
</ul>
<div id="package-naming-convention" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Package naming convention <a href="#package-naming-convention"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Finding a good name for a package is important. Other developers will use the package name in their source code, as a consequence, it must be informative but also shorts. A lot of blog articles have been written about this subject. In this section, I will give you some hints to choose your package name wisely :</p>
<div class="list">
<p>The name you choose must be small. In my opinion, no more than ten letters is a good length. Take an example from the standard library. Package names are very shot, often composed of a single word.</p>
<p>The name has to bring essential information with it. A good test is to ask somebody what your package does without showing him the source code. A bad answer should make you think a second time about the name.</p>
<p>Package names can be written in snake case (my_name) nothing in the specification is written about it, but the usage among the Go community is to use camel case (myPackageName)</p>
</div>
<div id="the-uniqueness-of-package-names" class="anchor"></div>
<h4 data-number="12.0.0.1"><span class="header-section-number">12.0.0.1</span> The uniqueness of package names <a href="#the-uniqueness-of-package-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Such small names often disconcert beginners because they fear from potential name collisions. The risk of collision exists but not at the level of the package name :</p>
<ul>
<li>The import path <strong>AND</strong> the package name must be unique.</li>
</ul>
<p>Another developer might have taken foo as a package name does not prevent me from choosing it because my package has not the same import path.</p>
<ul>
<li>Note that if someone has the same import path as you and the same package name, you can still compile your program</li>
</ul>
<div id="linking-package-together-a-trial-and-error-approach." class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Linking package together: a trial and error approach. <a href="#linking-package-together-a-trial-and-error-approach."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous chapter, all the code we produced was in the main package. We know how to create new packages that will contain functions, variables, constants... How to use functions from the package room into the package main?</p>
<p>Here is our package room :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/trial-error/room/room.go
package room

import &quot;fmt&quot;

// display information about a room
func printDetails(roomNumber, size, nights int) {
    fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}</code></pre>
<p>How to call the function <span v-highlightjs><code class="go" v-pre style="display: inline">printDetails</code></span> in the package main (our program’s entry point)? Let’s try to call the function directly :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/trial-error/main.go
package main

func main() {
    printDetails(112, 3, 2)
}</code></pre>
<p>If we compile this program, we got this error :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:4:2: undefined: printDetails</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">printDetails</code></span>, which we try to call, is not defined in the package main. However, the function is defined in the package room. We want to import the package room into the package main. To allow the import to work, we need to say that our program is a Go module.</p>
<p>To do that, we need to create a go.mod file at the root of our project :</p>
<pre v-highlightjs><code class="go" v-pre >module thisIsATest2

go 1.13</code></pre>
<p>The module path is set to <span v-highlightjs><code class="go" v-pre style="display: inline">thisIsATest2</code></span>. You can choose another one</p>
<p>Then we can import the room package into our main function :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/trial-error-2/main.go
package main

import &quot;thisIsATest2/room&quot;

func main() {
    printDetails(112, 3, 2)
}</code></pre>
<p>Let’s try to build our program. We got an error message :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:3:8: imported and not used: &quot;thisIsATest2/room&quot;
./main.go:6:2: undefined: printDetails</code></pre>
<p>This error says that :</p>
<ul>
<li><p>We import a package without using it.</p></li>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">printDetails</code></span> is still not defined.</p></li>
</ul>
<p>Go has no clue that we want to use the <span v-highlightjs><code class="go" v-pre style="display: inline">printDetails</code></span> from the room package. To inform it, we can use this notation :</p>
<pre v-highlightjs><code class="go" v-pre >room.printDetails(112, 3, 2)</code></pre>
<p>It means : “call the function <span v-highlightjs><code class="go" v-pre style="display: inline">printDetails</code></span> from package room”. Let’s try it :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/trial-error-3/main.go
package main

import &quot;thisIsATest2/room&quot;

func main() {
    room.printDetails(112, 3, 2)
}</code></pre>
<p>If we compile, we got another error :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:6:2: cannot refer to unexported name room.printDetails
./main.go:6:2: undefined: room.printDetails</code></pre>
<p>It gives us an indication: we are unable to call the function because it’s unexported... We need to export the function to make it visible to others! To do that in Go, you have to use a capital letter on the first letter of the function.</p>
<p>This is the new version of the room package source file :</p>
<pre v-highlightjs><code class="go" v-pre >// room/room.go
package room

import &quot;fmt&quot;

// display information about a room
func PrintDetails(roomNumber, size, nights int) {
   fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}</code></pre>
<p><u>printDetails</u> has been renamed to <strong><u>P</u></strong><u>rintDetails.</u></p>
<p>And this is our main function :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/trial-error-4/main.go
package main

import &quot;thisIsATest2/room&quot;

func main() {
    room.PrintDetails(112, 3, 2)
}</code></pre>
<p>The function call has been changed to reflect the new name of the method (<span v-highlightjs><code class="go" v-pre style="display: inline">room.PrintDetails</code></span>). Now we can launch the compilation of the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go</code></pre>
<p>And the execution :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./main
112 : 3 people / 2  nights</code></pre>
<p>Hooray ! it works. We have used an exported function from another package into the main package.</p>
<div id="key-takeaways" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>A function, a variable, and a constant if <strong>not exported</strong> is <strong>private</strong> to the package where it is defined</p></li>
<li><p>To <strong>export something</strong> just transform the first letter of its identifier to a <strong>capital</strong> letter</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// package-imports/package-example/price/price.go
package price

// this function is not exported
// we cannot use it in another package
func compute(){
    //
}

// this function is exported
// it can be used in another package
func Update(){
    //...
}</code></pre>
<div id="vocabulary-precisions" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Vocabulary precisions <a href="#vocabulary-precisions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>You might hear from other developers use the term <strong>public</strong> or <strong>private</strong>. Those terms are equivalent to exported / unexported. Other languages use them to refer to the same thing. This is not the official Go terminology.</p>
<ul>
<li><p>Example usage: “Mmm, why did you make this function <strong>public</strong>, it’s not even used outside your package?”</p></li>
<li><p>Example usage: “Bob, you should consider making this method <strong>private</strong>”</p></li>
</ul></li>
<li><p>The term “visibility” refers to the exported/unexported quality of a method.</p>
<ul>
<li>Example usage: “What is the visibility of this constant? It’s <strong>private</strong>”</li>
</ul></li>
</ul>
<div id="import-path-import-declaration" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Import Path &amp; Import declaration <a href="#import-path-import-declaration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To import a package into another package, you need to know its import path. We will see how to determine it in the first section.</p>
<p>When you know the import path, you have to write an import declaration. This is the object of the next section.</p>
<div id="import-path" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Import path <a href="#import-path"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The Go specifications do not strictly specify the import path. The import path is a string that must uniquely identify a module among all the existing modules. The build system will use them to fetch the source code of your imported packages and build your program. Today the Go build system (which code live in the package <strong>go/build</strong> rely on different types of path :</p>
<ul>
<li><p>Standard library path</p>
<ul>
<li><p>The source files that we will use to build your program are located by default in <strong>/usr/local/go/src</strong> (for Linux and Mac users) and in <strong>C:\Go\src</strong> for windows users</p>
<ul>
<li><u>Example</u> : <span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> ...</li>
</ul></li>
</ul></li>
<li><p>URL that points to a code-sharing website.</p>
<ul>
<li><p>Go has out-of-the-box support for the following code-sharing websites that use Git as a version control system (VCS): Github, Gitlab, Bitbucket.</p></li>
<li><p>The URL can be exposed on the internet (for instance, an open-source project hosted on Github)</p>
<ul>
<li><u>Example</u> : <span v-highlightjs><code class="go" v-pre style="display: inline">gitlab.com/loir402/foo</code></span></li>
</ul></li>
<li><p>It can also be only exposed in your local/company network</p>
<ul>
<li><u>Example</u> : <span v-highlightjs><code class="go" v-pre style="display: inline">acme-corp.company.gitlab.com/loir402/foo</code></span></li>
</ul></li>
</ul></li>
<li><p>Local path or relative path</p>
<ul>
<li>When Go has introduced modules, this type of import path is no longer the norm, even if the Go build system supports it.</li>
</ul></li>
</ul>
<p>When downloading the package go will check the type of VCS system used in the HTTP response (inside a meta tag). If it’s not provided, you should add to the import path the type of VCS system used. Here is an example from Go official documentation:</p>
<pre v-highlightjs><code class="go" v-pre >example.org/repo.git/foo/bar</code></pre>
<div id="what-is-a-vcs-what-is-git-github-gitlab" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> What is a VCS? What is Git, Github, Gitlab? <a href="#what-is-a-vcs-what-is-git-github-gitlab"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>VCS means Version Control System; it is a program that is designed to keep track of changes operated on source files of a project. VCS systems also have features that allow several developers to work on the same program simultaneously without annoying each other.</p>
<p>There are several VCS programs available on the market :</p>
<ul>
<li><p>Git</p></li>
<li><p>Mercurial</p></li>
<li><p>Bazaar</p></li>
<li><p>.…<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></p></li>
</ul>
<p>Git is Open source. It is very famous, and it is widely used today. (In my professional experience, I only used Git).</p>
<ul>
<li>It was created in 2005 by Linus Torvalds<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a>.</li>
</ul>
<p>It’s a program that you can use on the terminal, but also using a graphical interface.</p>
<p>The changes to the source code of Go are managed using Git.</p>
<p>Git is a <strong>distributed VCS.</strong> Individual developers will fetch a copy of the source code by creating a “local repository”. When a developer is happy with its changes, he can push them to a remote repository.</p>
<ul>
<li><p><strong>Github, Gitlab, Bitbucket</strong> : are websites that allow developers to host source code by initializing repositories hosted on their servers.</p></li>
<li><p>Those websites offer other features, but code hosting is their base activity.</p></li>
</ul>
<p>Those websites are a great way to show and share your work with others. They are also the basic block of the open-source community. I strongly advise you to create a profile on that website to show what you can do and help others.</p>
<div id="how-to-download-code-from-distant-packages" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> How to download code from distant packages <a href="#how-to-download-code-from-distant-packages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To download a package, you will use the <span v-highlightjs><code class="go" v-pre style="display: inline">go get</code></span> command inside your terminal.</p>
<p>For instance, if you want to import the code from https://github.com/graphql-go/graphql, open a terminal, and type :</p>
<pre v-highlightjs><code class="go" v-pre >go get github.com/graphql-go/graphql</code></pre>
<p>We will cover this in details in the chapter dedicated to the dependency management system.</p>
<div id="import-declaration" class="anchor"></div>
<h2 data-number="14.4"><span class="header-section-number">14.4</span> Import declaration <a href="#import-declaration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An import declaration declares that we will use some external code in the current package.</p>
<p>An import declaration can have different forms :</p>
<div id="standard-import" class="anchor"></div>
<h5 data-number="14.4.0.0.1"><span class="header-section-number">14.4.0.0.1</span> Standard import <a href="#standard-import"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<pre v-highlightjs><code class="go" v-pre >import &quot;gitlab.com/loir402/foo&quot;</code></pre>
<p>Then in the file, you can access the exported element of the imported package with the syntax :</p>
<pre v-highlightjs><code class="go" v-pre >foo.MyFunction()</code></pre>
<p>Here we use the function MyFunction from the foo package.</p>
<div id="multiple-package-import" class="anchor"></div>
<h5 data-number="14.4.0.0.2"><span class="header-section-number">14.4.0.0.2</span> Multiple package import <a href="#multiple-package-import"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>You rarely have just one import. Often you have several packages to import :</p>
<pre v-highlightjs><code class="go" v-pre >import (
   &quot;fmt&quot;
   &quot;maximilien-andile.com/application2/foo&quot;
   &quot;maximilien-andile.com/application2/bar&quot;
)</code></pre>
<p>Here we import three packages. Here are their import paths :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">maximilien-andile.com/application2/foo</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">maximilien-andile.com/application2/bar</code></span></p></li>
</ul>
<div id="import-with-an-explicit-package-name-alias" class="anchor"></div>
<h5 data-number="14.4.0.0.3"><span class="header-section-number">14.4.0.0.3</span> Import with an explicit package name (alias) <a href="#import-with-an-explicit-package-name-alias"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>You can also give your package an alias (a sort of surname for the package) to call its exported elements with this alias. For instance :</p>
<pre v-highlightjs><code class="go" v-pre >import bar &quot;gitlab.com/loir402/foo&quot;</code></pre>
<p>Here we say that the package has the surname <strong>bar</strong>. You can use the exported types, functions, variables, and constants of the package foo (which import path is “gitlab.com/loir402”) with the qualifier <strong>bar</strong> like this :</p>
<pre v-highlightjs><code class="go" v-pre >bar.MyFunction()</code></pre>
<div id="import-with-a-dot" class="anchor"></div>
<h5 data-number="14.4.0.0.4"><span class="header-section-number">14.4.0.0.4</span> Import with a dot <a href="#import-with-a-dot"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<pre v-highlightjs><code class="go" v-pre >import .&quot;gitlab.com/loir402/foo&quot;</code></pre>
<p>With this syntax, all the functions, types, variables, and constants will be declared in the current package. As a consequence, all the exported identifiers of the package can be used without any qualifier. I do not recommend using this because it can become confusing.</p>
<div id="the-blank-import" class="anchor"></div>
<h5 data-number="14.4.0.0.5"><span class="header-section-number">14.4.0.0.5</span> The blank import <a href="#the-blank-import"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>With a blank import, just the package’s init function will be called. A blank import can be specified with the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >import _ &quot;gitlab.com/loir402/foo&quot;</code></pre>
<div id="the-internal-directory" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> The internal directory <a href="#the-internal-directory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When you create a package and when your program is available on a code-sharing site (like Github, Gitlab ...), other developers will be able to use your package.</p>
<p>This is a great responsibility for you. Other programs will rely on your code. It means that when you change a function or the name of an Exported identifier, other code might break.</p>
<p>To forbid the import of packages, you can put them into a directory called “internal”.</p>
<figure>
<b-img :src="require('@/assets/images/internal_directory.png')" alt="Example usage of the internal directory"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Example usage of the internal directory</figcaption>
</figure>
<p>In the figure, you can see an example directory structure with an internal directory :</p>
<ul>
<li><p>cmd/main.go contain the main package and the main function</p></li>
<li><p>internal/booking is the directory of the package booking.</p></li>
<li><p>internal/booking/booking.go is a source file of the package booking.</p></li>
<li><p>Any exported identifiers inside the booking package are accessible into the current program (ie. we can call a function from booking package into package main)</p></li>
<li><p>BUT, other developers will not be able to use it in their program.</p></li>
</ul>
<div id="practical-application-2-refactor-source-code" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Practical application 2: refactor source code <a href="#practical-application-2-refactor-source-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="problem-1" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> Problem <a href="#problem-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>One of your colleagues has created a program composed of a unique main.go file :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/application-refactor/problem/main.go
package main

import &quot;fmt&quot;

func main() {

    // first reservation
    customerName := &quot;Doe&quot;
    customerEmail := &quot;john.doe@example.com&quot;
    var nights uint = 12
    emailContents := getEmailContents(&quot;M&quot;, customerName, nights)
    sendEmail(emailContents, customerEmail)
    createAndSaveInvoice(customerName, nights, 145.32)
}

// send an email
func sendEmail(contents string, to string) {
    // ...
    // ...
}

// prepare email template
func getEmailContents(title string, name string, nights uint) string {
    text := &quot;Dear %s %s,\n your room reservation for %d night(s) is confirmed. Have a nice day !&quot;
    return fmt.Sprintf(text,
        title,
        name,
        nights)
}

// create the invoice for the reservation
func createAndSaveInvoice(name string, nights uint, price float32) {
    // ...
}</code></pre>
<p>You are asked to refactor the code to improve its maintainability. Propose a new code organization :</p>
<ul>
<li><p>Which packages should you create?</p></li>
<li><p>Should you create new directories?</p></li>
</ul>
<div id="solution-1" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Solution <a href="#solution-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="the-go.mod-file-1" class="anchor"></div>
<h3 data-number="16.2.1"><span class="header-section-number">16.2.1</span> The go.mod file <a href="#the-go.mod-file-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The initial task is to create a new directory and create a go.mod file. You can do that manually, but let me show you how to do it with your terminal :</p>
<pre v-highlightjs><code class="go" v-pre >$ mkdir application2Test
$ go mod init maximilien-andile.com/packages/application2
go: creating new go.mod: module maximilien-andile.com/packages/application2</code></pre>
<p>This command will initialize automatically the go.mod file :</p>
<pre v-highlightjs><code class="go" v-pre >module maximilien-andile.com/packages/application2

go 1.13</code></pre>
<p>The module path is set to “maximilien-andile.com/packages/application2”. You can choose whatever you want.</p>
<div id="which-packages-to-create" class="anchor"></div>
<h3 data-number="16.2.2"><span class="header-section-number">16.2.2</span> Which packages to create? <a href="#which-packages-to-create"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The main package defines three functions :</p>
<ul>
<li><p>sendEmail</p></li>
<li><p>getEmailContents</p></li>
<li><p>createAndSaveInvoice</p></li>
</ul>
<p>Two functions are related to emails. One function related to invoices. We can create one package for emails and one package for invoices. The rule is simple: <strong>group into packages constructs that are related to the same theme</strong>.</p>
<p>Let’s find names for our packages :</p>
<ul>
<li><p>email</p></li>
<li><p>invoice</p></li>
</ul>
<p>The name has to remain short informative, and simple. Do not look for complexity; keep it simple and understandable. The next step is to create two directories to hold our source files.</p>
<figure>
<b-img :src="require('@/assets/images/folder_solution_package_app_2.png')" alt="Screenshot of the project folder in a MacOS finder"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Screenshot of the project folder in a MacOS finder</figcaption>
</figure>
<div id="the-email-package" class="anchor"></div>
<h3 data-number="16.2.3"><span class="header-section-number">16.2.3</span> The email package <a href="#the-email-package"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Inside the directory email, we will create a file named email.go :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/application-refactor/solution/email/email.go
package email

// send an email
func Send(contents string, to string) {
    // ...
    // ...
}

// prepare email template
func Contents(title string, name string, nights uint) string {
    // ...
    // TO IMPLEMENT
    return &quot;&quot;
}</code></pre>
<p>Note that we changed the name of the functions.</p>
<ul>
<li><p><u>sendEmail</u> has been replaced with <u>Send</u></p></li>
<li><p><u>getEmailContents</u> has been replaced with <u>Contents</u></p></li>
</ul>
<p>The first thing to note is that the two functions are exported. Names have been modified. Because we are in package email, we do not need to call the method SendEmail. Here is two examples call :</p>
<pre v-highlightjs><code class="go" v-pre >// version 1
email.SendEmail(&quot;test&quot;,&quot;john.doe@test.com&quot;)
// version 2
email.Send(&quot;test&quot;,&quot;john.doe@test.com&quot;)</code></pre>
<p>I hope that you prefer version 2... In version 1, we use the term email twice, which works but is not elegant.</p>
<p>We also changed the name of the second function (<u>getEmailContents</u>). We changed two things:</p>
<ol type="1">
<li><p>In other languages, methods that begin with the word “get” are pretty usual. In Go, it’s rare. Why? Because when you look at the function signature, the output is a string. You know that you will get your email contents as a string. Adding the “Get” do not bring more information to the caller. We get rid of it</p></li>
<li><p>We deleted the email reference because we are in the mail package. No need to repeat ourselves.</p></li>
</ol>
<div id="the-invoice-package" class="anchor"></div>
<h3 data-number="16.2.4"><span class="header-section-number">16.2.4</span> The invoice package <a href="#the-invoice-package"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Here is the source code of the invoice package :</p>
<pre v-highlightjs><code class="go" v-pre >// package-imports/application-refactor/solution/invoice/invoice.go
package invoice

// create the invoice for the reservation and
// save it to database
func Create(name string, nights uint, price float32) {
    // ...
}</code></pre>
<p>The function name has been reworked: we export it, and we have removed the reference to invoice in the name. Here is an example of a call to the function <u>Create</u> :</p>
<pre v-highlightjs><code class="go" v-pre >invoice.Create()</code></pre>
<p>If we did not change the name of the function, we could have something like that :</p>
<pre v-highlightjs><code class="go" v-pre >invoice.CreateInvoice()</code></pre>
<p>This syntax repeats the word <u>Invoice</u> unnecessarily.</p>
<div id="the-main-package-1" class="anchor"></div>
<h3 data-number="16.2.5"><span class="header-section-number">16.2.5</span> The main package <a href="#the-main-package-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// package-imports/application-refactor/solution/main.go
package main

import (
    &quot;maximilien-andile.com/packages/application2/email&quot;
    &quot;maximilien-andile.com/packages/application2/invoice&quot;
)

func main() {
    // first reservation
    customerName := &quot;Doe&quot;
    customerEmail := &quot;john.doe@example.com&quot;
    var nights uint = 12
    emailContents := email.Contents(&quot;M&quot;, customerName, nights)
    email.Send(emailContents, customerEmail)
    invoice.Create(customerName, nights, 145.32)
}</code></pre>
<p>First, note that we import two packages identified by their import path :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">maximilien-andile.com/package/application2/email</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">maximilien-andile.com/package/application2/invoice</code></span></p></li>
</ul>
<p>The main function is the entry point of our program. Three variables are defined.</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">customerName</code></span> (string)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">customerEmail</code></span> (string)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> (unsigned integer)</p></li>
</ul>
<p>Because we exposed the function <span v-highlightjs><code class="go" v-pre style="display: inline">Contents</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Send</code></span>, we are free to call them into the main package :</p>
<pre v-highlightjs><code class="go" v-pre >emailContents := email.Contents(&quot;M&quot;, customerName, nights)
email.Send(emailContents, customerEmail)</code></pre>
<p>Then we call the function <span v-highlightjs><code class="go" v-pre style="display: inline">Create</code></span> (also exposed) :</p>
<pre v-highlightjs><code class="go" v-pre >invoice.Create(customerName, nights, 145.32)</code></pre>
<div id="project-tree" class="anchor"></div>
<h3 data-number="16.2.6"><span class="header-section-number">16.2.6</span> Project tree <a href="#project-tree"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<figure>
<b-img :src="require('@/assets/images/tree_solution_package_application_2.png')" alt="Application solution project tree"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Application solution project tree</figcaption>
</figure>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="17.1"><span class="header-section-number">17.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>When you add this line :<span v-highlightjs><code class="go" v-pre style="display: inline">import _ "github.com/go-sql-driver/mysql"</code></span> to the import section of your program, what will happen?</p></li>
<li><p>What is the syntax to import the package “<strong>github.com/PuerkitoBio/goquery</strong>” with the alias “<strong>goq</strong>” ?</p></li>
<li><p>How can you share your Go code with others?</p></li>
<li><p>How to spot exported identifiers in a package?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="17.2"><span class="header-section-number">17.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What the following line does:<span v-highlightjs><code class="go" v-pre style="display: inline">import _ "github.com/go-sql-driver/mysql"</code></span> ?</p>
<ol type="1">
<li><p>This is a blank import declaration.</p></li>
<li><p>It is said to be blank because of the underscore character<span v-highlightjs><code class="go" v-pre style="display: inline">_</code></span>.</p></li>
<li><p>All init functions of the package <strong>github.com/go-sql-driver/mysql</strong>will be run</p></li>
</ol></li>
<li><p>What is the syntax to import a package with an alias?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">import goq "github.com/PuerkitoBio/goquery"</code></span></li>
</ol></li>
<li><p>How can you share your Go code with others?</p>
<ol type="1">
<li><p>Create a git repository on a code hosting website (like Github, GitLab, bitbucket ...)</p>
<ol type="1">
<li>Let say that you created the repository gitlab.com/loir402/foo</li>
</ol></li>
<li><p>Initialize your module (with<span v-highlightjs><code class="go" v-pre style="display: inline">go mod init gitlab.com/loir402/foo</code></span>)</p>
<ol type="1">
<li>It will create a go.mod file at your project’s root.</li>
</ol></li>
<li><p>Push your code to the hosting website.</p></li>
<li><p>Share it with your colleagues and friends who will import it.</p></li>
<li><p>Note that you can also send your code by email or via physical mail, but it might be not optimal :)</p></li>
</ol></li>
<li><p>How to spot exported identifiers in a package?</p>
<ol type="1">
<li><p>Their first letter is capitalized</p></li>
<li><p>On the contrary unexported identifiers have not a first letter capitalized</p>
<ol type="1">
<li><p>ex:<span v-highlightjs><code class="go" v-pre style="display: inline">const FontSize = 12</code></span> is an exported constant identifier</p></li>
<li><p>ex:<span v-highlightjs><code class="go" v-pre style="display: inline">const emailLengthLimit = 58</code></span> is an unexported constant identifier</p></li>
</ol></li>
</ol></li>
</ol>
<div id="key-takeaways-1" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Key takeaways <a href="#key-takeaways-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A package is a group of source files that live in the same directory</p></li>
<li><p>A name identifies a package</p></li>
<li><p>Identifiers with a first letter uppercase are exported.</p></li>
<li><p>An exported identifier can be used in any other packages.</p></li>
<li><p>Packages that live inside an internal directory can be used inside packages of your module. However, they cannot be used by other modules.</p></li>
<li><p>A module is “a collection of Go packages stored in a file tree with a go.mod file at its root”<a href="#fn6" class="footnote-ref" id="fnref6" role="doc-noteref"><sup>6</sup></a></p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://blog.golang.org/using-go-modules<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>a secret management software (https://github.com/hashicorp/vault)<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>a system for managing containerized applications across multiple hosts (HTTPS://github.com/kubernetes/kubernetes)<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>You can see a list of available software here: https://en.wikipedia.org/wiki/List_of_version-control_software<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>Linus Torvald is the creator and principal developer of the Linux Kernel.<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn6" role="doc-endnote"><p>https://blog.golang.org/using-go-modules<a href="#fnref6" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap11PackagesAndImports"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap10Functions'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Functions</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap12PackageInitialization'}">
									<p><u><small>Next</small></u></p>
									<p><small>Package Initialization</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Packages and imports - Practical Go Lessons"
const description = "A package is composed of one or more source files. Into those source files, the Go programmer declares : constants variables, functions and types and methods. The package main is often composed of an individual file. The function main is the program entry point"

export default {
  name: "Chap11PackagesAndImports",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
